/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  CButtonGroup,
  CButton,
  // CDropdown,
  // CDropdownToggle,
  // CDropdownMenu,
  // CDropdownItem,
  // CImage,
  CCard,
  CCardHeader,
  CCardBody,
  CBadge,
  CSmartPagination,
  CCol,
} from '@coreui/react-pro'

import SmartTable from 'src/components/SmartTable/SmartTable'
import { getUsers } from 'src/request/user'
import { LIMIT_TO_SHOW, TABLE_HEAD } from '../constants'
import { getNameRole } from '../helpers'
import Filter from '../components/Filter'
import './styles.css'

const Usuarios = () => {
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0)
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPage] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [filters, setFilters] = useState({})

  const MIN_PAGE = 1
  const showPage = page + MIN_PAGE

  const handleOffset = (offset) => (offset >= 0 ? offset : 0)

  const handleChangePage = (value = 1) => {
    const pageToQuery = value ? +value - 1 : 0
    setPage(pageToQuery)
  }

  const handleChangeFilter = (e) => {
    const { target } = e;
    const { value = '' } = target;
    setInputValue(value)

    if (value !== '') {
      setFilters({
        email: value,
      });
    }
  }

  const getUsersList = (filters = {}, cajasDate) => {
    setLoading(true)

    getUsers({
      ...filters,
    })
      .then(({ data }) => {
        const { users = [], total = 0 } = data
        setTotal(total)
        setUsers(users)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  useEffect(() => {
    getUsersList(filters);
  }, [filters])

  useEffect(() => {
    getUsersList({
      ...filters,
      limit: LIMIT_TO_SHOW,
      offset: handleOffset(Math.ceil(page * LIMIT_TO_SHOW)),
    })
  }, [filters, page])

  const handleClearFilter = () => {
    setInputValue('');
    setFilters({});
  }

  const handleSendInput = (e) => {
    e.preventDefault()
    setFilters({
      emails: String(inputValue),
    });
  }

  useEffect(() => {
    if (total) setTotalPage(Math.ceil(total / LIMIT_TO_SHOW))
  }, [total])

  return (
    <CCard className="table-list">
      <CCardHeader>
        <h1 className="h3">Usuarios</h1>
      </CCardHeader>
      <CCardBody>
        <div className="position-relative">
          <Filter
            handleChangeFilter={handleChangeFilter}
            handleSendInput={handleSendInput}
            handleClearFilter={handleClearFilter}
            inputValue={inputValue}
            placeholder="ingrese email"
            button={
              <CCol
                className="align-self-end btn-create"
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <CButtonGroup>
                  <Link to="/usuarios/crear">
                    <CButton color="success">Crear Usuario</CButton>
                  </Link>
                </CButtonGroup>
              </CCol>
            }
          />
          <div className="table-list__toolbar-table">
            {/* <CButtonGroup>
              <Link to="/usuarios/crear">
                <CButton color="success">Crear Usuario</CButton>
              </Link>
              <CDropdown variant="btn-group">
            <CDropdownToggle color="secondary">Descargar </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem href="#">PDF</CDropdownItem>
              <CDropdownItem href="#">CSV</CDropdownItem>
              <CDropdownItem href="#">JSON</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
            </CButtonGroup> */}
          </div>
        </div>

        <SmartTable
          responsive="true"
          items={users}
          columns={TABLE_HEAD}
          columnFilter
          filter={false}
          // itemsPerPageSelect
          itemsPerPage={LIMIT_TO_SHOW}
          loading={loading}
          sorterValue={{
            key: 'date',
          }}
          tableProps={{
            hover: true,
          }}
          scopedColumns={{
            role: (item) => (
              <td>
                {item?.roles?.map(({ id = 0, name: roleName = '' }) => (
                  <CBadge
                    color="light"
                    key={roleName}
                    style={{ textTransform: 'capitalize', marginRight: 5 }}
                  >
                    {getNameRole(id)}
                  </CBadge>
                ))}
              </td>
            ),
            edit: (item) => (
              <td>
                <Link to={`/usuarios/detalle/${item.id}`}>
                  <CButton color="secondary">Editar</CButton>
                </Link>
              </td>
            ),
          }}
          clickableRows
        />
        <CSmartPagination
          activePage={showPage}
          pages={totalPages}
          onActivePageChange={handleChangePage}
        />
      </CCardBody>
    </CCard>
  )
}

export default Usuarios
